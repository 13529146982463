<template>
    <div class="tree-com">
        <Optional
            :allCheck="allCheck"
            :multiple="multiple"
            :checkUserOnly="checkUserOnly"
            :checkAll="checkAll"
            :dropDownSearchType="dropDownSearchType"
            :treeShowList="treeShowList"
            :breadcrumbsList="breadcrumbsList"
            :optionalLoadTitle="optionalLoadTitle"
            :searchDropdownData="searchDropdownData"
            :searchOptionalLoadTitle="searchOptionalLoadTitle"
            :currentType="currentType"
            :meetingSelectPeopleList="meetingSelectPeopleList"
            :selectTreeNodeData="selectTreeNodeData"
            :placeholder="placeholder"
            :isGroup="isGroup"
            :groupOptions="groupOptions"
            @selectFocus="selectFocus"
            @selectInput="selectInput"
            @handlerAllTreeNodeClick="handlerAllTreeNodeClick"
            @handlerTreeNodeClick="handlerTreeNodeClick"
            @treeNodeNextLevelClick="treeNodeNextLevelClick"
            @changeTreeListLevel="changeTreeListLevel"
            @changeLaunchType="changeLaunchType"
            @meetingPeopleListChange="meetingPeopleListChange"
            @deleteGroup="deleteGroup"
            @handlerGroupClick="handlerGroupClick"

        />
        <Selected :selectTreeNodeData="selectTreeNodeData" @saveGroup="saveGroup" @handlerDelTreeNode="handlerDelTreeNode" :isGroup="isGroup"/>
    </div>
</template>
<script>
import Optional from "../components/Optional/index.vue";
import Selected from "../components/Selected/index.vue";
export default {
    name: "TreeCom",
    components: {
        Optional,
        Selected,
    },
    props: {
        meetingSelectPeopleList:Array,
        currentType:Number,
        allCheck: Boolean,
        multiple: Boolean,
        dropDownSearchType: Boolean,
        treeShowList: Array,
        selectTreeNodeData: Array,
        breadcrumbsList: Array,
        optionalLoadTitle: String,
        searchDropdownData: Array,
        searchOptionalLoadTitle: String,
        placeholder: String,
        checkUserOnly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        checkAll: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        groupOptions:{
            type: Array,
            default: () => {
                return [];
            }
        },
        isGroup:{
            type:Boolean,
            default: () => {
                return false;
            }
        },
    },
    methods: {
        meetingPeopleListChange(list){
            this.$emit('meetingPeopleListChange',list)
        },
        changeLaunchType(type){
            this.$emit('changeLaunchType',type)
        },
        selectFocus () {
            this.$emit('selectFocus')
        },
        selectInput (inputVal) {
            this.$emit('selectInput', inputVal)
        },
        handlerAllTreeNodeClick (data) {
            this.$emit("handlerAllTreeNodeClick", data)
        },
        handlerTreeNodeClick(data) {
            this.$emit("handlerTreeNodeClick", data);
        },
        treeNodeNextLevelClick (data) {
            this.$emit("treeNodeNextLevelClick", data);
        },
        handlerDelTreeNode (data) {
            this.$emit("handlerDelTreeNode", data);
        },
        changeTreeListLevel (data) {
            this.$emit("changeTreeListLevel", data)
        },
        saveGroup(data){
            this.$emit("saveGroup", data)
        },
        /**
         * @Description: 删除分组
         * @Author: 王梦瑶
         * @Date: 2024-09-19 16:26:35
         */
         deleteGroup(data){
            this.$emit("deleteGroup", data)
         },
          /**
         * @Description: 分组点击事件
         * @Author: 王梦瑶
         * @Date: 2024-09-19 16:26:35
         */
         handlerGroupClick(data){
            this.$emit("handlerGroupClick", data)
         }
    },
};
</script>
<style lang="scss" scoped>
.tree-com {
    display: flex;
    justify-content: center;
}
</style>
